import {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import { BranchContext } from "./BranchContext";
import { AuthenticationContext } from "../features/login/contexts/AuthenticationContext";
import { isEmpty } from "../helpers/General";
import { SideNavContext } from "./SideNavContext";
import moment from "moment";
import DataReformation from "../helpers/DataReformation";
import {
  JSONToCSVConvertor,
  JSONToCSVConvertorRevamp,
  removeBreakingCharacters,
} from "../helpers/excelHelpers";
import { getComplaints, getReviews } from "../features/overall-view/api";
import { getComplaintIssues } from "../features/facility-management/api";
import { getExternalReputationResponses } from "../features/revamped-overall-view/api/reputation-responses";

export const BranchHeaderContext = createContext();

// Search Related

const BranchHeaderContextProvider = (props) => {
  const [downloadMethodOpen, setDownloadMethodOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState({ isError: false });
  const [starredFilter, setStarredFilter] = useState("All");
  const [resolvedFilter, setResolvedFilter] = useState("All");
  //   const [searchQuery, setSearchQuery] = useState("");
  const [issues, setIssues] = useState(null);
  const [selectedIssue, setSelectedIssue] = useState(0);
  const [selectedDivisions, setSelectedDivisions] = useState([]);

  const [branchesTemplateHistory, setBranchesTemplateHistory] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  const { currBranchInfo, headerBranch } = useContext(BranchContext);
  const { user } = useContext(AuthenticationContext);
  const { sideNav } = useContext(SideNavContext);

  const today = new Date();
  const monthAgo = new Date();
  monthAgo.setMonth(monthAgo.getMonth() - 1);

  let createdAt = currBranchInfo["created_at"];
  let minDate = monthAgo < new Date(createdAt) ? new Date(createdAt) : monthAgo;
  const [duration, setDuration] = useState({
    from: minDate,
    to: today,
  });

  const getIssues = (props) => {
    if (!!props?.hardRefresh) {
      setIssues(null);
    }
    if (issues === null || !!props?.hardRefresh)
      getComplaintIssues()
        .then((response) => {
          setIssues(response);
        })
        .catch((error) => console.error(error));
  };

  const resetHeaderFilters = () => {
    const today = new Date();
    const monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    let createdAt = currBranchInfo["created_at"];
    let minDate =
      monthAgo < new Date(createdAt) ? new Date(createdAt) : monthAgo;
    setDuration({ from: minDate, to: today });
    setSelectedIssue(0);
    // setSearchQuery("");
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!currBranchInfo || isEmpty(currBranchInfo)) {
      return;
    }
    resetHeaderFilters();

    let ids = currBranchInfo.divisions.map((div) => div.id);
    currBranchInfo ? setSelectedDivisions([...ids]) : setSelectedDivisions([]);
  }, [user, currBranchInfo]);

  useEffect(() => {
    if (sideNav === "Complaints") {
      getIssues();
    }
  }, [sideNav]);

  const changeDate = (dateType, date) => {
    const toDate = new Date(duration.to);
    const fromDate = new Date(duration.from);
    if (dateType === "From") {
      if (moment(date).isAfter(moment(toDate), "D")) {
        let nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        setDuration({ ...duration, from: new Date(date), to: nextDay });
      } else {
        setDuration({ ...duration, from: new Date(date) });
      }
    } else {
      if (dateType === "To") {
        if (moment(date).isBefore(moment(fromDate), "D")) {
          let prevDay = new Date(date);
          prevDay.setDate(prevDay.getDate() - 1);
          setDuration({ ...duration, from: prevDay, to: new Date(date) });
        } else {
          setDuration({ ...duration, to: new Date(date) });
        }
      }
    }
  };

  const toggleResolvedFilter = (clearResolvedFilter) => {
    if (!!clearResolvedFilter) {
      setResolvedFilter("All");
    } else {
      if (resolvedFilter === "All") setResolvedFilter("resolved");
      else setResolvedFilter("All");
    }
  };

  const toggleStarredFilter = (clearStarredFilter) => {
    if (clearStarredFilter) {
      setStarredFilter("All");
    } else {
      if (starredFilter === "All") {
        setStarredFilter("Starred");
      } else if (starredFilter === "Starred") {
        setStarredFilter("All");
      }
    }
  };

  //   const handleSearch = (e) => {
  //     setSearchQuery(e.target.value);
  //   };

  const downloadExcelRevamp = (data, entityToExportFrom) => {
    DataReformation.reformDate(duration.from);
    JSONToCSVConvertorRevamp(
      data,
      entityToExportFrom +
        " from (" +
        DataReformation.reformDate(duration.from) +
        ") (" +
        DataReformation.reformDate(duration.to) +
        ")",
      entityToExportFrom
    );
  };

  const downloadExcel = (data, entityToExportFrom) => {
    DataReformation.reformDate(duration.from);
    JSONToCSVConvertor(
      data,
      entityToExportFrom +
        " from (" +
        DataReformation.reformDate(duration.from) +
        ") (" +
        DataReformation.reformDate(duration.to) +
        ")",
      entityToExportFrom
    );
  };

  const downloadMethodHandleChangeRevamp = ({
    queryStringsForAPI,
    entityToExportFrom,
    questionsDataToRender,
  }) => {
    // Download As Excel
    let durationData = {};
    setDownloading(true);
    if (entityToExportFrom === "Complaints") {
      getComplaints({
        ...queryStringsForAPI,
        no_pagination: true,
      })
        .then((data) => {
          durationData = DataReformation.reformDataForComplaintsRevamped(data);
          downloadExcelRevamp(durationData, entityToExportFrom);
          setDownloading(false);
          setDownloadError({ isError: false });
        })
        .catch((error) => {
          setDownloading(false);
          console.error(
            error,
            "Failed to Fetch and Compute Complaints for Export",
            "BranchHeaderContext"
          );
          if (error) {
            setDownloadError({
              isError: true,
              errorMessage: "Error in downloading excel",
            });
          }
        });
    } else if (entityToExportFrom === "Reviews") {
      getReviews({
        ...queryStringsForAPI,
        no_pagination: true,
      })
        .then((data) => {
          const reformedData = prepareReviewDataForDownloadRevamp({
            data,
            questionsDataToRender,
          });
          downloadExcel(reformedData, entityToExportFrom);
          setDownloading(false);
          setDownloadError({ isError: false });
        })
        .catch((error) => {
          setDownloading(false);
          if (error) {
            setDownloadError({
              isError: true,
              errorMessage: "Error in downloading excel",
            });
          }
        });
    } else if (entityToExportFrom === "ReputationResponses"){
        getExternalReputationResponses({
            ...queryStringsForAPI,
            no_pagination: true,
          })
            .then((data) => {
              durationData = DataReformation.reformDataForReputationResponses(data.data);
              downloadExcelRevamp(durationData, entityToExportFrom);
              setDownloading(false);
              setDownloadError({ isError: false });
            })
            .catch((error) => {
              setDownloading(false);
              console.error(
                error,
                "Failed to Fetch and Compute Reputation Responses for Export",
                "BranchHeaderContext"
              );
              if (error) {
                setDownloadError({
                  isError: true,
                  errorMessage: "Error in downloading excel",
                });
              }
            });
    }
  };

  const downloadMethodHandleChange = ({
    event,
    questions,
    rawSelectedRecords,
    queryStringsForAPI,
    resetSelectedRows,
    entityToExportFrom,
    questionsDataToRender,
  }) => {
    // Download As Excel
    let divisionsQueryApplied = false;
    // if (selectedDivisions.length !== currBranchInfo.divisions.length) {
    //   divisionsQueryApplied = selectedDivisions;
    // }
    if (event.target.value === "Excel") {
      let durationData = {};
      setDownloading(true);
      if (entityToExportFrom === "Complaints") {
        if (rawSelectedRecords !== undefined) {
          durationData =
            DataReformation.reformDataForComplaints(rawSelectedRecords);
          downloadExcel(durationData, entityToExportFrom);
          setDownloading(false);
          setDownloadError({ isError: false });
          resetSelectedRows();
        } else {
          getComplaints({
            ...queryStringsForAPI,
            no_pagination: true,
          })
            .then((data) => {
              durationData = DataReformation.reformDataForComplaints(
                data,
                rawSelectedRecords
              );

              downloadExcel(durationData, entityToExportFrom);
              setDownloading(false);
              setDownloadError({ isError: false });
              resetSelectedRows();
            })
            .catch((error) => {
              setDownloading(false);
              console.error(
                error,
                "Failed to Fetch and Compute Complaints for Export",
                "BranchHeaderContext"
              );
              if (error) {
                setDownloadError({
                  isError: true,
                  errorMessage: "Error in downloading excel",
                });
              }
            });
        }
      } else if (entityToExportFrom === "Reviews") {
        if (rawSelectedRecords !== undefined) {
          const reformedData = prepareReviewDataForDownload({
            data: rawSelectedRecords,
            questionsDataToRender,
          });
          downloadExcel(reformedData, entityToExportFrom);
          setDownloading(false);
          setDownloadError({ isError: false });
          resetSelectedRows();
        } else {
          getReviews({
            ...queryStringsForAPI,
            no_pagination: true,
          })
            .then((data) => {
              const reformedData = prepareReviewDataForDownload({
                data,
                questionsDataToRender,
              });
              downloadExcel(reformedData, entityToExportFrom);
              setDownloading(false);
              setDownloadError({ isError: false });
              resetSelectedRows();
            })
            .catch((error) => {
              setDownloading(false);
              if (error) {
                setDownloadError({
                  isError: true,
                  errorMessage: "Error in downloading excel",
                });
              }
            });
        }
      }
    }
  };

  const prepareReviewDataForDownload = ({ data, questionsDataToRender }) => {
    let reformedData = [];

    data.map((dataRecord) => {
      let record = {
        ...dataRecord,
        Comment: removeBreakingCharacters(dataRecord.comment_text),
      };
      const reviewActions = dataRecord.action_history.actions;
      const latestActionInReview = reviewActions[reviewActions.length - 1];

      record["Latest_Action"] =
        reviewActions.length === 0 ? "-" : latestActionInReview.action;
      record["Action_Comment"] =
        reviewActions.length === 0
          ? "-"
          : removeBreakingCharacters(
              latestActionInReview[
                latestActionInReview.action === "note" ? "text" : "comment"
              ]
            );

      record.name = !!dataRecord.contacts?.name
        ? removeBreakingCharacters(dataRecord.contacts.name)
        : "";
      record.email = !!dataRecord.contacts?.email
        ? dataRecord.contacts.email
        : "";
      record.number = !!dataRecord.contacts?.number
        ? dataRecord.contacts.number
        : !!dataRecord.contacts?.phone
        ? dataRecord.contacts.phone
        : "";

      questionsDataToRender.map((questionData) => {
        const questionText = questionData.text.replaceAll(",", " ");
        const questionAnswerDataFromRawRecord = dataRecord.answers.filter(
          (recordAnswer) => recordAnswer.question === questionData.id
        );
        let questionAnswerText = "";

        if (questionAnswerDataFromRawRecord.length === 0) {
          questionAnswerText = "-";
        } else {
          const choiceID = questionAnswerDataFromRawRecord[0].choice;
          const chosenAnswerObject = questionData.choices.filter(
            (globalQuestionAnswer) => globalQuestionAnswer.id === choiceID
          )[0];

          questionAnswerText = chosenAnswerObject?.text;
        }

        return (record[questionText.replaceAll(" ", "_")] = questionAnswerText);
      });

      const branchName = record.branch.name;
      record.branch = branchName;

      delete record.answers;
      delete record.contacts;
      delete record["action_history"];
      delete record["comment_text"];
      return reformedData.push(record);
    });

    return reformedData;
  };

  const prepareReviewDataForDownloadRevamp = ({
    data,
    questionsDataToRender,
  }) => {
    try {
      let reformedData = [];

      data.map((dataRecord) => {
        let record = {
          id: dataRecord.id,
          Submitted_at: dataRecord.submitted_at,
          Branch: dataRecord.branch.name,
          Division: dataRecord.division,
          Channel: dataRecord.channel,
          Comment: removeBreakingCharacters(dataRecord.comment_text) || '',
          Comment_Audio: dataRecord.comment_audio || '',
        };

        record.Customer_Name = !!dataRecord.contacts?.name
          ? removeBreakingCharacters(dataRecord.contacts.name)
          : "";

        record.Customer_Phone = !!dataRecord.contacts?.number
          ? dataRecord.contacts.number
          : !!dataRecord.contacts?.phone
          ? dataRecord.contacts.phone
          : "";

        questionsDataToRender.map((questionData) => {
          const questionText = questionData.text
            .replaceAll(" ", "_")
            .replaceAll(",", "_");
          const questionAnswerDataFromRawRecord = dataRecord.answers.filter(
            (recordAnswer) => recordAnswer.question === questionData.id
          );
          let questionAnswerText = "";

          if (questionAnswerDataFromRawRecord.length === 0) {
            questionAnswerText = "-";
          } else {
            const choiceID = questionAnswerDataFromRawRecord[0].choice;
            const chosenAnswerObject = questionData.choices.filter(
              (globalQuestionAnswer) => globalQuestionAnswer.id === choiceID
            )[0];

            questionAnswerText = chosenAnswerObject?.text;
          }

          return (record[questionText] = questionAnswerText);
        });

        return reformedData.push(record);
      });

      return reformedData;
    } catch (error) {
      console.error(
        error,
        "Error in Preparing Review Data for Export - BranchHeaderContext"
      );
    }
  };

  return (
    <BranchHeaderContext.Provider
      value={{
        duration,
        setDuration,
        downloadMethodOpen,
        setDownloadMethodOpen,
        starredFilter,
        toggleStarredFilter,
        resolvedFilter,
        toggleResolvedFilter,
        // searchQuery,
        // handleSearch,
        selectedDivisions,
        setSelectedDivisions,
        issues,
        getIssues,
        selectedIssue,
        setSelectedIssue,
        changeDate,
        downloadMethodHandleChange,
        downloadMethodHandleChangeRevamp,
        downloading,
        downloadError,
        branchesTemplateHistory,
        selectedTemplate,
        setSelectedTemplate,
      }}
    >
      {props.children}
    </BranchHeaderContext.Provider>
  );
};

export { BranchHeaderContextProvider };
