import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { COLORS } from "../../../helpers/Colors";
import LoadingInModal from "./LoadingInModal";
import { createSingleLink } from "../api";

const useStyles = makeStyles(() => ({
  qrCode: {
    color: "#262222",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "10px",
  },
  QRCodeImage: {
    marginTop: "20px",
  },
  downloadQRButton: {
    fontFamily: "Avenir-Medium",
    cursor: "Pointer",
    background: COLORS.meliorYellow,
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    margin: "20px 0px",
    marginBottom: "0px",
    padding: "15px 10px",
    "&:hover": {
      background: COLORS.meliorYellowGentle,
    },
  },
}));

const SingleQRCodeModalBody = ({
  branchId,
  divisionId,
  branchName,
  divisionName,
  setError,
  surveyVariant
}) => {
  const classes = useStyles();

  const [qrLink, setQrLink] = useState("");

  const [loading, setLoading] = useState(false);
  const [imgload, setimgload] = useState(false);

  let src = "";


  useEffect(() => {
    generateQRCode();
  }, []);

  useEffect(() => {
    src = `https://api.qrserver.com/v1/create-qr-code/?data=${qrLink}&size=300x300`;
  }, [qrLink]);

  const generateQRCode = () => {
    setimgload(true);
    createSingleLink({
      branchId: branchId,
      divisionId: divisionId,
      type: surveyVariant,
      qr: true,
    })
      .then((response) => {
        setError({
          isError: false,
          errorMessage: "",
        });

        setQrLink(response.link);
      })
      .catch((error) => {
        setError({
          isError: true,
          errorMessage: error?.response.data.non_field_errors
              ? error?.response.data.non_field_errors[0]
              : "Request failed, please try again later.",
        });
      });
  };

  const downloadQRCode = () => {
    setLoading(true);
    fetch(
      `https://api.qrserver.com/v1/create-qr-code/?data=${qrLink}&size=300x300;`,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          setError({
            isError: false,
            errorMessage: "",
          });
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${branchName} - ${divisionName} - QR - Code - ${new Date().toLocaleDateString()}.png`.replace(
              / /g,
              ""
            )
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
        setError({
          isError: true,
          errorMessage:
            "We couldn't download that QR code for you, check your internet connection and try again",
        });
      });
  };

  return (
    <div>
      {!qrLink ? (
        <LoadingInModal />
      ) : (
        <div className={classes.qrCode}>
          {imgload && <LoadingInModal height="80px" />}
          <img
            className={classes.QRCodeImage}
            style={
              imgload ? { visibility: "hidden" } : { visibility: "visible" }
            }
            src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrLink}&size=300x300;`}
            alt="qrCode"
            onLoad={() => setimgload(false)}
          />
          {loading ? (
            <LoadingInModal />
          ) : (
            <Button
              download
              onClick={downloadQRCode}
              className={classes.downloadQRButton}
            >
              Download
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
export default SingleQRCodeModalBody;
