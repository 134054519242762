import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { COLORS } from "../../../helpers/Colors";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import LoadingInModal from "./LoadingInModal";
import readXlsxFile from "read-excel-file";
import { DropzoneArea } from "react-mui-dropzone";
import { sendBulkSMS } from "../api";
import { DatePicker } from "@mui/lab";
import moment from "moment";
import { getDivisionTemplatesForBulkSMS } from "../api/getDivisionTemplatesForBulkSMS";

const useStyles = makeStyles(() => ({
  actionButton: {
    fontFamily: "Avenir-Medium",
    cursor: "Pointer",
    background: COLORS.meliorYellow,
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    margin: "10px 0px",
    padding: "8px 20px",
    "&:hover": {
      background: COLORS.meliorYellowGentle,
    },
  },
  notice: {
    margin: "20px 0px",
  },
  dropZoneText: {
    fontSize: "16px",
    margin: "30px",
    fontFamily: "Avenir-Medium",
  },
  dropZoneContainer: {
    height: "fit-content",
    minHeight: "200px",
    padding: "10px",
    borderColor: COLORS.meliorYellow,
  },
  feedback: {
    height: "110px",
    width: "100%",
  },
  recordsContainer: {
    marginTop: "10px",
  },
  record: {
    border: `1px solid ${COLORS.greyGentle}`,
  },
  smsInfoTitle: {
    fontSize: "14px",
    margin: "8px 0",
  },
  smallActionButton: {
    fontFamily: "Avenir-Medium",
    cursor: "Pointer",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    margin: "0",
    padding: "3px 6px",
    textDecoration: "underline",
    fontWeight: "600",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const BulkSendSMSModalBody = ({
  branchId,
  divisionId,
  setError,
  surveyVariant,
}) => {
  const classes = useStyles();
  const [numbersWithVisitDate, setNumbersWithVisitDate] = useState([]);
  const [recordsFound, setRecordsFound] = useState(0);
  const [numbersMissingZeroWithVisitDate, setNumbersMissingZeroWithVisitDate] =
    useState([]);
  const [numbersInvalidCountOrForm, setNumbersInvalidCountOrForm] = useState(
    []
  );
  const [availableTimings, setAvailableTimings] = useState(hours);
  const [scheduleDate, setScheduleDate] = useState(moment());
  const [scheduleTime, setScheduleTime] = useState(availableTimings[0].value);
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const [done, setDone] = useState(false);
  const [parsed, setParsed] = useState(false);

  const [parsingNumbersError, setParsingNumbersError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [loading, setLoading] = useState(false);
  const [sheet, setSheet] = useState(null);

  const updateTemplateHandler = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const timeIsValid = ({ timeToCheck, currentHour, currentMinute }) => {
    const [hourToCheck, minuteToCheck] = timeToCheck.split(":");

    if (Number(hourToCheck) === Number(currentHour)) {
      if (Number(minuteToCheck) > Number(currentMinute)) {
        return true;
      }
    } else if (Number(hourToCheck) > Number(currentHour)) {
      return true;
    }

    return false;
  };

  const updateScheduleDateHandler = (newDate) => {
    setScheduleDate(moment(newDate));
  };

  const updateScheduleTimeHandler = (newTimeEvent) => {
    setScheduleTime(newTimeEvent.target.value);
  };

  const parseNumbersFomSheet = () => {
    let currentNumbersInvalidCountOrForm = [];
    let currentNumbersMissingZeroWithVisitDate = [];
    let currentRecordsFound = 0;
    try {
      readXlsxFile(sheet)
        .then((currSheet) => {
          let rowNames = currSheet[0];
          let columnIndexOfPhoneNumber = rowNames.indexOf(
            rowNames.find(
              (rowName) =>
                rowName.toLowerCase().includes("phone") ||
                rowName.toLowerCase().includes("mobile")
            )
          );
          let columnIndexOfVisitDate = rowNames.indexOf(
            rowNames.find(
              (rowName) =>
                rowName?.toLowerCase().includes("visit") ||
                rowName?.toLowerCase().includes("date")
            )
          );
          if (columnIndexOfPhoneNumber === -1) {
            setError({
              isError: true,
              errorMessage: `We couldn't find a column header with "phone numbers" or "mobile numbers"`,
            });
          }

          var numbersWithVisitDateTemp = currSheet.reduce(
            (accumlator, currRow) => {
              const currentNumber =
                currRow[columnIndexOfPhoneNumber]?.toString();
              const currentVisitDate =
                columnIndexOfVisitDate !== -1
                  ? currRow[columnIndexOfVisitDate]?.toString()
                  : undefined;
              const formattedVisitDate = currentVisitDate
                ? moment(currentVisitDate).format("YYYY-MM-DD hh:mm")
                : undefined;
              if (
                !!currentNumber &&
                currentNumber?.trim().length &&
                currentNumber !== rowNames[columnIndexOfPhoneNumber]
              ) {
                currentRecordsFound += 1;
                if (
                  (currentNumber.length === 11 && currentNumber[0] === "0") ||
                  (currentNumber.length === 12 &&
                    currentNumber.substring(0, 2) === "20") ||
                  (currentNumber.length === 13 &&
                    currentNumber.substring(0, 3) === "+20")
                ) {
                  accumlator.push({
                    mobile_number: currentNumber,
                    visit_date: formattedVisitDate,
                  });
                } else {
                  if (currentNumber.length === 10 && currentNumber[0] === "1") {
                    currentNumbersMissingZeroWithVisitDate.push({
                      mobile_number: currentNumber,
                      visit_date: formattedVisitDate,
                    });
                  } else {
                    currentNumbersInvalidCountOrForm.push(currentNumber);
                  }
                }
              }
              console.log(accumlator, "acccc");
              return accumlator;
            },
            []
          );

          setNumbersWithVisitDate(numbersWithVisitDateTemp);
          setNumbersInvalidCountOrForm(currentNumbersInvalidCountOrForm);
          setNumbersMissingZeroWithVisitDate(
            currentNumbersMissingZeroWithVisitDate
          );
          setRecordsFound(currentRecordsFound);
          setParsed(true);
        })
        .catch(() =>
          setError({
            isError: true,
            errorMessage:
              "We couldn't parse your sheet, please re-create your sheet and try again",
          })
        );
    } catch (exception) {
      setError({
        isError: true,
        errorMessage:
          "We couldn't parse your sheet, please re-create your sheet and try again",
      });
    }
  };

  const prefillWithZero = () => {
    setNumbersWithVisitDate([
      ...numbersWithVisitDate,
      ...numbersMissingZeroWithVisitDate.map((numberWithVisitDate) => ({
        ...numberWithVisitDate,
        mobile_number: `0${numberWithVisitDate.mobile_number}`,
      })),
    ]);
    setNumbersMissingZeroWithVisitDate([]);
    if (!numbersInvalidCountOrForm.length) {
      setParsingNumbersError({ isError: false, errorMessage: "" });
    }
  };

  const sendSMS = () => {
    setLoading(true);
    const [hour, minute] =
      scheduleTime !== 0
        ? scheduleTime.split(":")
        : [moment().hour(), moment().minute()];

    const dateAndTimeObject = scheduleDate.set({
      hour: Number(hour),
      minute: Number(minute),
    });

    const dateAndTimeISOString = dateAndTimeObject.toISOString();

    sendBulkSMS({
      branchID: branchId,
      divisionID: divisionId,
      templateID: selectedTemplate,
      scheduled_date: dateAndTimeISOString,
      numbersWithVisitDate: numbersWithVisitDate,
      type: surveyVariant,
    })
      .then(() => {
        setLoading(false);
        setDone(true);
        setError({
          isError: false,
          errorMessage: "",
        });
      })
      .catch((error) => {
        if (error?.response?.status === 423) {
          setError({
            isError: true,
            errorMessage:
              "A sheet is in progress right now, please try again in a while",
          });
        } else {
          setError({
            isError: true,
            errorMessage: error?.response.data.non_field_errors
              ? error?.response.data.non_field_errors[0]
              : "Request failed, please try again later.",
          });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    setDone(false);
    setParsed(false);
    setNumbersWithVisitDate([]);
    setNumbersInvalidCountOrForm([]);
    setNumbersMissingZeroWithVisitDate([]);
    setParsingNumbersError({ isError: false, errorMessage: "" });
    setRecordsFound(0);
    setError({ isError: false, errorMessage: "" });
    setLoading(false);
    if (!sheet) return;
    parseNumbersFomSheet();
  }, [sheet]);

  useEffect(() => {
    if (recordsFound !== numbersWithVisitDate.length) {
      setParsingNumbersError({
        isError: true,
        errorMessage: "We found some invalid numbers",
      });
    } else {
      setParsingNumbersError({
        isError: false,
        errorMessage: "",
      });
    }
  }, [recordsFound, numbersWithVisitDate]);

  useEffect(() => {
    const currentHour = moment().hour();
    const currentMinute = moment().minute();
    const currentDate = moment();

    const selectedDateAfterToday = scheduleDate.isAfter(currentDate);

    const availableTimingsTemp = selectedDateAfterToday
      ? hours
      : hours.filter((hourData, index) =>
          timeIsValid({
            currentHour,
            currentMinute,
            timeToCheck: hourData.value,
          })
        );

    // Add Now time if the date is today
    if (!selectedDateAfterToday) {
      availableTimingsTemp.unshift({ label: "Now", value: 0, bold: true });
    }

    setScheduleTime(availableTimingsTemp[0].value);

    setAvailableTimings(availableTimingsTemp);
  }, [scheduleDate]);

  useEffect(() => {
    getDivisionTemplatesForBulkSMS(divisionId).then((divisionTemplates) => {
      const templatesOptionsTemp = divisionTemplates.map((templateData) => {
        return {
          label: templateData.name,
          value: templateData.id,
        };
      });

      setTemplatesOptions([...templatesOptionsTemp]);
      setSelectedTemplate(divisionTemplates[0].id);
    });
  }, [divisionId]);

  return (
    <Box
      width="90%"
      color={COLORS.brightBlack}
      margin="10px auto"
      textAlign="center"
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Typography fontSize="1.2rem" fontWeight="bold">
        Multiple SMS Generator
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        gap={1}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap={4}
          alignItems="center"
        >
          <DatePicker
            label="Date"
            value={scheduleDate}
            minDate={new Date()}
            onChange={(newValue) => {
              updateScheduleDateHandler(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />

          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel id="demo-simple-select-label">Time</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={scheduleTime}
              label="Age"
              onChange={updateScheduleTimeHandler}
              MenuProps={{ style: { maxHeight: 500 } }}
            >
              {availableTimings.map((timeData, index) => (
                <MenuItem
                  value={timeData.value}
                  sx={{
                    fontWeight: timeData.bold ? "bold" : "",
                    fontSize: timeData.bold ? "1rem" : "",
                  }}
                >
                  {timeData.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {templatesOptions.length > 0 && (
          <FormControl sx={{ width: "200px" }}>
            <InputLabel id="tempalte">Template</InputLabel>
            <Select
              labelId="tempalte"
              id="demo-simple-select"
              defaultValue={templatesOptions[0].value}
              value={selectedTemplate}
              label="Template"
              onChange={updateTemplateHandler}
              disabled={templatesOptions.length === 1}
              title={
                templatesOptions.length === 1 ? "Only 1 Template exists" : ""
              }
            >
              {templatesOptions.map((templateOption) => {
                return (
                  <MenuItem value={templateOption.value}>
                    {templateOption.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Box>

      <DropzoneArea
        onChange={(file) => setSheet(file[0])}
        dropzoneText={`Drag and Drop your excel sheet here. The column that contains the numbers must have a header title of 'Phone Numbers' or 'Mobile Numbers'. And the optional column for Visit Date must contain a 'Visit Date' title.`}
        acceptedFiles={[
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ]}
        dropzoneParagraphClass={classes.dropZoneText}
        useChipsForPreview={true}
        filesLimit={1}
        dropzoneClass={classes.dropZoneContainer}
        Icon={CloudUploadOutlinedIcon}
        showPreviews={false}
        showPreviewsInDropzone={false}
      />
      <div className={classes.feedback}>
        {!numbersWithVisitDate.length && !done && !loading && (
          <UserErrorSuccessFormDisplay
            bg={COLORS.meliorYellowGentle}
            type="info"
            message={`All Mobile Numbers should start with +20, 20 or 0.`}
            width="100%"
            margin="10px auto"
          />
        )}
        <Grid container alignItems="center" justifyContent="space-between">
          {numbersWithVisitDate.length > 0 && (
            <>
              <p className={classes.notice}>
                {recordsFound} record{recordsFound !== 1 ? "s" : ""} found,{" "}
                {numbersWithVisitDate.length} of which{" "}
                {numbersWithVisitDate.length !== 1 ? "are" : "is"} valid
              </p>
            </>
          )}

          {!numbersWithVisitDate.length && !!parsed && (
            <UserErrorSuccessFormDisplay
              color={COLORS.brightBlack}
              type="error"
              message={
                recordsFound === 0
                  ? `No records found`
                  : `No valid records found`
              }
              width="100%"
              margin="10px auto"
            />
          )}

          {!loading && !done && numbersWithVisitDate.length > 0 && (
            <Tooltip title={`Send ${numbersWithVisitDate.length} SMS`}>
              <Button className={classes.actionButton} onClick={sendSMS}>
                {parsingNumbersError.isError
                  ? `Send only valid number`
                  : `Send`}
              </Button>
            </Tooltip>
          )}
        </Grid>
        {!!parsingNumbersError.isError && (
          <UserErrorSuccessFormDisplay
            color={COLORS.brightBlack}
            type="error"
            message={parsingNumbersError.errorMessage}
            width="100%"
            margin="10px auto"
          />
        )}
        {!!parsingNumbersError.isError &&
          !!numbersMissingZeroWithVisitDate.length && (
            <Grid container spacing={2} className={classes.recordsContainer}>
              <Grid container justifyContent="space-between">
                <p className={classes.smsInfoTitle}>
                  These number seem to miss a "0" at the beginning
                </p>
                <Tooltip
                  title="A common issue we found is that some file editors remove the 0 from number,
                if we think this is the issue, we can add a 0 to the beginning of these numbers"
                >
                  <Button
                    className={classes.smallActionButton}
                    onClick={prefillWithZero}
                  >
                    Prefill with 0
                  </Button>
                </Tooltip>
              </Grid>
              {numbersMissingZeroWithVisitDate.map((record, index) => (
                <Grid
                  key={`${record.mobile_number}${index}`}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.record}
                  style={{ padding: "5px" }}
                >
                  {record.mobile_number}
                </Grid>
              ))}
            </Grid>
          )}
        {!!parsingNumbersError.isError &&
          !!numbersInvalidCountOrForm.length && (
            <Grid container spacing={2} className={classes.recordsContainer}>
              <Grid container justifyContent="space-between">
                <p className={classes.smsInfoTitle}>
                  These number seem to be in the wrong format
                </p>
              </Grid>
              {numbersInvalidCountOrForm?.map((number, index) => (
                <Grid
                  key={`${number}${index}`}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.record}
                  style={{ padding: "5px" }}
                >
                  {number}
                </Grid>
              ))}
            </Grid>
          )}
        {!loading && !done && !!numbersWithVisitDate.length && (
          <Grid container spacing={2} className={classes.recordsContainer}>
            <Grid container justifyContent="space-between">
              <p className={classes.smsInfoTitle}>Valid numbers</p>
            </Grid>
            {numbersWithVisitDate.map((record, index) => (
              <Grid
                key={`${record.mobile_number}${index}`}
                item
                xs={12}
                sm={4}
                md={3}
                className={classes.record}
                style={{ padding: "5px" }}
              >
                {record.mobile_number}
              </Grid>
            ))}
          </Grid>
        )}
        {!numbersWithVisitDate.length && loading && !done && (
          <LoadingInModal height="30px" />
        )}
        {done && (
          <UserErrorSuccessFormDisplay
            color={COLORS.white}
            bg={COLORS.successGreen}
            message="Your request is being processed, Await our email for total confirmaton."
            type="success"
            width="100%"
            margin="30px auto"
            padding="15px 15px"
          />
        )}
      </div>
    </Box>
  );
};
export default BulkSendSMSModalBody;

const hours = [
  { value: "00:00", label: "00:00", bold: true },
  { value: "00:15", label: "00:15" },
  { value: "00:30", label: "00:30" },
  { value: "00:45", label: "00:45" },

  { value: "01:00", label: "01:00", bold: true },
  { value: "01:15", label: "01:15" },
  { value: "01:30", label: "01:30" },
  { value: "01:45", label: "01:45" },

  { value: "02:00", label: "02:00", bold: true },
  { value: "02:15", label: "02:15" },
  { value: "02:30", label: "02:30" },
  { value: "02:45", label: "02:45" },

  { value: "03:00", label: "03:00", bold: true },
  { value: "03:15", label: "03:15" },
  { value: "03:30", label: "03:30" },
  { value: "03:45", label: "03:45" },

  { value: "04:00", label: "04:00", bold: true },
  { value: "04:15", label: "04:15" },
  { value: "04:30", label: "04:30" },
  { value: "04:45", label: "04:45" },

  { value: "05:00", label: "05:00", bold: true },
  { value: "05:15", label: "05:15" },
  { value: "05:30", label: "05:30" },
  { value: "05:45", label: "05:45" },

  { value: "06:00", label: "06:00", bold: true },
  { value: "06:15", label: "06:15" },
  { value: "06:30", label: "06:30" },
  { value: "06:45", label: "06:45" },

  { value: "07:00", label: "07:00", bold: true },
  { value: "07:15", label: "07:15" },
  { value: "07:30", label: "07:30" },
  { value: "07:45", label: "07:45" },

  { value: "08:00", label: "08:00", bold: true },
  { value: "08:15", label: "08:15" },
  { value: "08:30", label: "08:30" },
  { value: "08:45", label: "08:45" },

  { value: "09:00", label: "09:00", bold: true },
  { value: "09:15", label: "09:15" },
  { value: "09:30", label: "09:30" },
  { value: "09:45", label: "09:45" },

  { value: "10:00", label: "10:00", bold: true },
  { value: "10:15", label: "10:15" },
  { value: "10:30", label: "10:30" },
  { value: "10:45", label: "10:45" },

  { value: "11:00", label: "11:00", bold: true },
  { value: "11:15", label: "11:15" },
  { value: "11:30", label: "11:30" },
  { value: "11:45", label: "11:45" },

  { value: "12:00", label: "12:00", bold: true },
  { value: "12:15", label: "12:15" },
  { value: "12:30", label: "12:30" },
  { value: "12:45", label: "12:45" },

  { value: "13:00", label: "13:00", bold: true },
  { value: "13:15", label: "13:15" },
  { value: "13:30", label: "13:30" },
  { value: "13:45", label: "13:45" },

  { value: "14:00", label: "14:00", bold: true },
  { value: "14:15", label: "14:15" },
  { value: "14:30", label: "14:30" },
  { value: "14:45", label: "14:45" },

  { value: "15:00", label: "15:00", bold: true },
  { value: "15:15", label: "15:15" },
  { value: "15:30", label: "15:30" },
  { value: "15:45", label: "15:45" },

  { value: "16:00", label: "16:00", bold: true },
  { value: "16:15", label: "16:15" },
  { value: "16:30", label: "16:30" },
  { value: "16:45", label: "16:45" },

  { value: "17:00", label: "17:00", bold: true },
  { value: "17:15", label: "17:15" },
  { value: "17:30", label: "17:30" },
  { value: "17:45", label: "17:45" },

  { value: "18:00", label: "18:00", bold: true },
  { value: "18:15", label: "18:15" },
  { value: "18:30", label: "18:30" },
  { value: "18:45", label: "18:45" },

  { value: "19:00", label: "19:00", bold: true },
  { value: "19:15", label: "19:15" },
  { value: "19:30", label: "19:30" },
  { value: "19:45", label: "19:45" },

  { value: "20:00", label: "20:00", bold: true },
  { value: "20:15", label: "20:15" },
  { value: "20:30", label: "20:30" },
  { value: "20:45", label: "20:45" },

  { value: "21:00", label: "21:00", bold: true },
  { value: "21:15", label: "21:15" },
  { value: "21:30", label: "21:30" },
  { value: "21:45", label: "21:45" },

  { value: "22:00", label: "22:00", bold: true },
  { value: "22:15", label: "22:15" },
  { value: "22:30", label: "22:30" },
  { value: "22:45", label: "22:45" },

  { value: "23:00", label: "23:00", bold: true },
  { value: "23:15", label: "23:15" },
  { value: "23:30", label: "23:30" },
  { value: "23:45", label: "23:45" },
];
